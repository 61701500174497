import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { BadgeIcon, Space, Size, IconActionCheck } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, Polymorphic } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badgeicon"
    }}>{`BadgeIcon`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { BadgeIcon } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=576%253A1626'} storybook={'/?path=/story/badge-badgeicon--playground'} vue={'/components/HseBadge/HseBadgeIcon.html'} vueStorybook={'/?path=/story/badge-badgeicon--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`BadgeIcon — это компонент, отображающий аватар пользователя (изображение или иконка), либо его инициалы.`}</p>
    <p>{`Изображение передается в проп `}<inlineCode parentName="p">{`image`}</inlineCode>{`, иконка в проп `}<inlineCode parentName="p">{`icon`}</inlineCode>{`, инициалы в проп `}<inlineCode parentName="p">{`content`}</inlineCode>{`.`}</p>
    <p>{`Бэджу можно добавить подпись (например, имя пользователя) с помощью пропа `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`indicator`}</inlineCode>{` можно показать наличие уведомления.`}</p>
    <p>{`Пропом `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` можно задать отключенное состояние.`}</p>
    <p>{`Для добавления кликабельности передайте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{`.`}</p>
    <Polymorphic component="BadgeIcon" mdxType="Polymorphic" />
    <Playground __position={2} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <BadgeIcon\n    image={\n      \'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\'\n    }\n    label=\"Badge\"\n  />\n  <Space size={Size.small} horizontal />\n  <BadgeIcon content={\'BB\'} label=\"Badge\" indicator clickable />\n  <Space size={Size.small} horizontal />\n  <BadgeIcon icon={IconActionCheck} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <BadgeIcon image={'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} label='Badge' mdxType="BadgeIcon" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <BadgeIcon content={'BB'} label='Badge' indicator clickable mdxType="BadgeIcon" />
    <Space size={Size.small} horizontal mdxType="Space" />
    <BadgeIcon icon={IconActionCheck} mdxType="BadgeIcon" />
  </div>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У Badge 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Передайте нужный в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{`.`}</p>
    <Playground __position={3} __code={'() => {\n  return [\'small\', \'medium\', \'large\'].map((size, index) => (\n    <div key={index}>\n      <BadgeIcon size={size} label={size} />\n      <Space size=\"small_2x\" vertical />\n    </div>\n  ))\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return ['small', 'medium', 'large'].map((size, index) => <div key={index}>
        <BadgeIcon size={size} label={size} mdxType="BadgeIcon" />
        <Space size="small_2x" vertical mdxType="Space" />
      </div>);
      }}
    </Playground>
    <h3 {...{
      "id": "изображение"
    }}>{`Изображение`}</h3>
    <p>{`Для добавления изображения передайте в свойство `}<inlineCode parentName="p">{`image`}</inlineCode>{` ссылку`}</p>
    <Playground __position={4} __code={'<BadgeIcon\n  label=\"Badge\"\n  size=\"medium\"\n  image=\"https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png\"\n  content=\"Some content\"\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BadgeIcon label="Badge" size="medium" image="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" content="Some content" mdxType="BadgeIcon" />
    </Playground>
    <h3 {...{
      "id": "иконка"
    }}>{`Иконка`}</h3>
    <p>{`Badge может содержать любую иконку. Передайте ее в проп `}<inlineCode parentName="p">{`icon`}</inlineCode>{`.`}</p>
    <p>{`Размер иконки зависит от размера компонента`}</p>
    <Playground __position={5} __code={'<BadgeIcon label=\"Badge\" size=\"medium\" icon={IconActionCheck} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BadgeIcon label="Badge" size="medium" icon={IconActionCheck} mdxType="BadgeIcon" />
    </Playground>
    <h3 {...{
      "id": "индикатор"
    }}>{`Индикатор`}</h3>
    <p>{`Для добавления индикатора передайте проп `}<inlineCode parentName="p">{`indicator`}</inlineCode>{`. Индикатором можно показать наличие уведомлений, новых сообщений`}</p>
    <Playground __position={6} __code={'<BadgeIcon\n  label=\"Badge\"\n  size=\"medium\"\n  icon={IconActionCheck}\n  indicator\n  clickable\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BadgeIcon label="Badge" size="medium" icon={IconActionCheck} indicator clickable mdxType="BadgeIcon" />
    </Playground>
    <h3 {...{
      "id": "кликабельность"
    }}>{`Кликабельность`}</h3>
    <p>{`Передайте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{` для добавления возможности кликать.`}</p>
    <p>{`Вы также можете задать проп `}<inlineCode parentName="p">{`component`}</inlineCode>{`, чтобы определить тип базового компонента (например, `}<inlineCode parentName="p">{`a`}</inlineCode>{` или `}<inlineCode parentName="p">{`button`}</inlineCode>{`).
Чтобы задать реакцию на клик, обработайте реакцию на событие `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`, либо передайте дополнительные
пропы в соответствии с базовым компонентом (например, `}<inlineCode parentName="p">{`href`}</inlineCode>{` для `}<inlineCode parentName="p">{`a`}</inlineCode>{`).`}</p>
    <Playground __position={7} __code={'() => {\n  const [count, setCount] = useState(0)\n  return (\n    <BadgeIcon\n      label={`Clicked ${count} times`}\n      content=\"click\"\n      size=\"large\"\n      clickable\n      onClick={() => setCount(s => s + 1)}\n      component=\"button\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      BadgeIcon,
      Space,
      Size,
      IconActionCheck,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [count, setCount] = useState(0);
        return <BadgeIcon label={`Clicked ${count} times`} content="click" size="large" clickable onClick={() => setCount(s => s + 1)} component="button" mdxType="BadgeIcon" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={BadgeIcon} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент, тип которого определяется пропом `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      